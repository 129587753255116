import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col, Card } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesSYPKylin: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should You Pull Kylin?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_kylin.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should You Pull Kylin?</h1>
          <h2>
            A dedicated guide for Kylin that will help you decide whether to
            pull him or not.
          </h2>
          <p>
            Last updated: <strong>14/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                Second Square type Lightning Echomancer and very first (and
                only!) 6 star Lightning Vanguard, Kylin sets himself as an
                excellent enabler for many burst teams while still offering CC
                and defensive utility. While his use case is fairly specific, it
                is far from a niche. Kylin is able to fit in a huge number of
                teams, while also making it much easier to build multiple teams
                for content where Echomancers can only be played once. Kylin’s
                main draw is of course his exceptional ability to activate the
                Memory Trace “My Vision Is Clear!”, given that he’s a rare case
                of Lightning Square Echomancer. He can also keep up Barriers for
                extended periods of time (another rare trait) and offer
                Displacement tools to interrupt enemies and prepare the ground
                for burst of damage output. Whilst not a must-have to clear
                content in general, Kylin is an good pick-up for any player
                looking to build multiple MVIC-based teams for multi-team
                encounters like Echo Hunt or Simultaneous Raids.
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Review" />
              <p>
                Although Kylin possesses a pretty stacked skill set with lots of
                effects, ranging from stance switch to spammable CCs and passive
                Barriers, the way you’re going to use him most of the time is
                very straightforward: do you want to use “My Vision is Clear!”?
                If so, then he’s currently the best enabler for the
                Electroconduct reaction, and that alone can make getting a copy
                of him worth it. Without taking the Memory Trace into account,
                however, Kylin positions himself as a Jack of all trades, master
                of none. With a plethora of tools at his disposal, but
                ultimately the lack of specialization of his kit hurting his
                potential to excel on his own merits.
              </p>
              <p>
                Talking about his skills, Kylin’s abilities have a limited
                amount of skill uses (4 and 3 respectively), which he
                compensates with multiple auto-activated abilities. By default,
                Kylin starts the fights in [Defense] stance and only switches to
                [Offense] stance by using his Seed skill. After that, as long as
                he doesn’t use a skill or “Deactivate” to exit [Offense] stance,
                he shall remain in this mode. He also creates 3 small barriers
                in front of himself at the start of the fight, which regenerate
                over time. Be careful though, as the barriers move constantly
                according to where Kylin is facing, so they’re not exactly the
                most reliable to stop projectiles.
              </p>
              <p>
                His skill 1, “Electro Web I”, pulls enemies to him and fully
                restores his Block gauge. This move will be the main way to draw
                multiple enemies into your Water Zones and pack them up nicely
                for your Burst, so use it sparingly!
              </p>
              <p>
                “Thunder Net”, his skill 2, includes both an auto-activated
                ability and an active one: Kylin periodically fires off one of
                his weapons randomly while in [Defense] stance. The active part
                of the skill on the other hand creates AoEs in two locations,
                the one where Kylin stands after repositioning and the one that
                he aims at. For a few seconds, enemies will be damaged and
                stunned periodically, further improving Kylin’s CC and defensive
                sides.
              </p>
              <p>
                Moving on to his Ultimate skill, “Go, Unfallen!” is a lot more
                simple than it looks when first looking at the amount of text.
                Kylin switches to his [Offense] stance for a nice boost in
                personal damage and starts blasting with his randomized weapons,
                each described in the skill. However, since Kylin immediately
                exits that stance as soon as he uses any of his active skills.
                This mode is mostly going to be used to break Block Gauges early
                (thanks to the 70% charge upon starting the fight) or to keep
                activating Lightning related Nexus skills once he’s run out of
                combat skill uses. It’s also worth noting that the range of this
                skill can reach 1500 radius, and the bombardment can remain
                active for up to 10 seconds. This large area coverage enables
                lasting pressure on the enemy waves while keeping your team
                safe. At the end of the activation, [Deactivate] can be used to
                restore a percentage of Kylin’s HP, while also restoring some of
                his Seed Charge. Thanks to these perks, he can be quite
                self-reliant on the front-line, with constant alternating
                between [Offense] and [Defense] modes despite the apparent long
                Seed Skill cooldown.
              </p>
              <p>
                Even though Kylin looks like a true multipurpose tool, he falls
                short on multiple aspects as his personal damage is still very
                low compared to any carry and some other fellow specialists, and
                his barriers are difficult to capitalize on. His Block Breaking
                capabilities force him to momentarily give up on his CCs, a bit
                of a shame really.
              </p>
              <p>
                When strictly talking about his team building however, much like
                Lorelle, he does fit into almost any team. Enabling a second
                MVIC team for multiple team content and being an upgrade to Yuqi
                is his main use. He is also a welcome addition to any stages
                that require multiple or consistent barriers or that have large
                waves of enemies.
              </p>
              <h5>Pros & Cons</h5>
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Jack of all trades;</li>
                        <li>No Dupes required;</li>
                        <li>Very quick Ult for Block Gauge Breaking;</li>
                        <li>Good at grouping enemies;</li>
                        <li>Stuns a lot;</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Small upgrade when compared to Yuqi and Mars;</li>
                        <li>
                          Stun capability is redundant in Electroconduct teams;
                        </li>
                        <li>
                          Barriers are not reliable on autoplay and require
                          manual attention from the player.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay Tips" />
              <h5>Dupes</h5>
              <p>
                Kylin doesn’t really need any dupes. Being purely an utility
                character, the value of his dupes come in small quality of life
                passives. His most valuable dupe is his first one, which grants
                him an additional use of his skill 2, “Thunder Net”. It also
                upgrades its effects, granting it a displacement feature similar
                to the skill 1, “Electro Web I”, but with a weaker displacement
                strength. The third dupe may seem tempting, considering its
                innate ATK boost. However, considering Kylin’s low damage and
                the fact that this boost is limited to when he’s in Offense
                stance, this dupe can only be recommended to players who are
                spenders or want to min-max teams where Kylin is present.
              </p>
              <h5>General Tips</h5>
              <ul>
                <li>
                  Much like other Vanguards, keeping the balance between
                  [Defense] and [Offense] stances is key to using Kylin
                  effectively. In his case, save his ultimate to apply pressure
                  on large waves of enemies or when his barriers breaks, as
                  switching back to [Defense] mode will not only restore them
                  but also restore this Echomancer’s HP by a small percentage.
                  Much like his Seed skill animation, this is a ‘panic button’.
                </li>
                <li>
                  Kylin fares better in manual gameplay. His barriers will face
                  the same direction that his mecha is facing. This can be
                  tricky at first due to the mecha’s egg shape, so use the
                  cannon as an arrow to help guide your gaze.
                </li>
                <li>
                  His skills have a small amount of uses, avoid using them
                  unless strictly necessary. In most cases, (Electroconduct
                  teams) the periodic stuns will happen passively, while his
                  skill 1 and Seed skill can be reserved for setting up big
                  plays.
                </li>
              </ul>
              <h5>Skill Upgrade Priority</h5>
              <ul>
                <li>
                  The skill 2, “Thunder net” is the easy choice for priority.
                  Its passive damage will be Kylin’s main source of output,
                  while the first skill is used mainly for its grouping utility.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Recommendation" />
              <p>
                NOTE: Remember you can mix and match Echomancers among all
                suggestions and alternate options, adjusting based on who you
                have available.
              </p>
              <h5>Team 1</h5>
              <p>
                Leader: <strong>Cen Ying</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>NON-STOP CHARGE</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cen-ying"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="luke"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="kylin"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="boreas"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                The ever-evolving summoner team has yet another update to its
                hall of possibilities. With Kylin as the frontline, this team
                can exert more pressure than ever. IMPORTANT! If you bring this
                team to a Dry or Normal weather stage, you will need to bring a
                source of water zone, such as Furay & Furney, Bubbles, or the
                upcoming Uni. After the initial setup, you can exchange them
                away for Luke or Freda. The support option recommended here is
                Boreas, as this team implies that you will use it on short
                stages or multi-team content, where Freda will often be busy
                with other teams. However, you can opt to use the combo Freda +
                Boreas in some occasions and Joint Training bosses in order to
                maximize Cen Ying’s ultimate uptime and damage.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="furay-and-furney"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="bubbles"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="boss"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Can’t Hurt to be
                Resourceful, Devotion, Class Link - Diamond, Getting the Groove
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />{' '}
                </li>
                <li>
                  <AshTrace name="Born From Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Racing Night" mode="data" onProfile /> (if
                  using Luke) or{' '}
                  <AshTrace name="Silent Yesterday" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="My Vision is Clear!" mode="data" onProfile />{' '}
                  (Core)
                </li>
                <li>
                  <AshTrace name="Into the Fantasy" mode="data" onProfile /> (if
                  using Boreas) or{' '}
                  <AshTrace name="Company" mode="data" onProfile />
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Festive Night" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Between Light and Shadow"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
              </ul>
              <h5>Team 2</h5>
              <p>
                Leader: <strong>Scarlett</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>MVIC: WIND</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="scarlett"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="shiratori-azusa"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="kylin"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                The Wind version of the MVIC summoner team, and excellent for
                multi-team content. As usual, start the stage by setting up a
                water zone if there isn’t one already, otherwise you can bring
                Furay & Furnay, Bubbles or Uni to do lay the ground and then
                switch to Freda. Being very straightforward, This is a simple
                case of stacking the two of the strongest damage multipliers
                combos in the game, and letting Scarlett do her job. The only
                downside is that this team may struggle with quick stacking of
                Inspire, so it may be a good idea to bring Longqing as well and
                swap Freda later. Or, in the case of Multi-team content, you can
                opt to using Longqing for this team and delegate Freda to
                another.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="longqing"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="furay-and-furney"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="bubbles"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="begonia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="felicio"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="boss"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Class Link - Triangle,
                Getting into the Swing, Bonus Time, Winning Chase
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Blood Ruvia - Secret" mode="data" onProfile />{' '}
                  (Core)
                </li>
                <li>
                  <AshTrace name="Dusk Radiance" mode="data" onProfile /> (Core)
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />{' '}
                  (Core)
                </li>
                <li>
                  <AshTrace name="My Vision is Clear!" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Nightmare" mode="data" onProfile /> (if
                  bringing Longqing)
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Testing Limits" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Secret Melody" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
              <h5>Team 3</h5>
              <p>
                Leader: <strong>Cyros</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>EXPLOSIVE ALTERNATIVES</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cyros"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="skylar"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="kylin"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Yet another team who focuses on taking advantage of stacked
                bonuses of Elemental Res shred and Elemental reactions. Skylar
                can boost both her own damage and Kylin’s. With the arrival of
                the Memory Trace “Testing Limits”, which benefits both Cyros and
                Kylin, Cyros is most likely to outpace any of the current
                Lightning DPS options, while Skylar brings extra utility, CC,
                and helps maintain a steady trigger of Memory Trace skills to
                further boost his damage.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="lydia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="hassel"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="sorley"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="baili-tusu"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="longqing"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </p>
              <p>
                <strong>Core Nexus Skills</strong>: Class Link - Diamond (or
                Square), Energy Release, Strength Works Miracles, Devotion
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile /> or
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Testing Limits" mode="data" onProfile />{' '}
                  (Core)
                </li>
                <li>
                  <AshTrace name="Into the Fantasy" mode="data" onProfile />{' '}
                  (Core)
                </li>
                <li>
                  <AshTrace name="Company" mode="data" onProfile /> (Core)
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Between Light and Shadow"
                    mode="data"
                    onProfile
                  />{' '}
                  or
                  <AshTrace name="Nightmare" mode="data" onProfile /> (if
                  bringing Longqing and/or not using Lucky Find)
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Future Synergies" />
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="uni"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                The prime Water Zone Echomancer, Uni makes the life of any
                Electroconduct team a lot easier and more efficient. She also
                brings damage, buffs and utility of her own, being a significant
                upgrade option for all the MVIC teams
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="elpis"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Kylin is the prime choice of frontline for teams centered around
                Elpis, since he ensures that enemies will stay within her AoE
                zone even without an Electroconduct setup. Furthermore, with
                Elpis as an addition to the Wind team, the already mentioned
                Scarlett + MVIC combo becomes even more powerful
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Review</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay Tips</a>
                </li>
                <li>
                  <a href="#section-3">Team Recommendation</a>
                </li>
                <li>
                  <a href="#section-4">Future Synergies</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesSYPKylin;

export const Head: React.FC = () => (
  <Seo
    title="Should You Pull Kylin | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for Kylin that will help you decide whether to pull him or not."
    game="ash"
  />
);
